<template>
	<div class="">
		<div
			class="Main margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div class="MainLeft" v-if="informationDetails.content != null">
				<div class="wz_detail_pc" v-if="!this.isMobileDevice">
					<div class="wz_detail_pc_content" v-if="!this.isMobileDevice">
						<div
							class="wz_detail_pc_content_title"
							:prohibitTran="informationDetails.prohibit_tran"
						>
							{{
								localLanguage == 2
									? informationDetails.title
										? informationDetails.title
										: informationDetails.title
									: informationDetails.title
							}}
						</div>
						<div class="wz_detail_pc_content_info flex align_items_c">
							<div
								v-if="
									informationDetails.author != null &&
									informationDetails.author.avatar_img != null
								"
							>
								<img
									:src="informationDetails.author.avatar_img"
									alt=""
									class="wz_detail_pc_content_img"
								/>
							</div>
							<div
								v-if="
									informationDetails.author != null &&
									informationDetails.author.nickname != null
								"
								>{{ informationDetails.author.nickname }}
							</div>
							<div>·</div>
							<div
								v-if="informationDetails.release_time != null"
								v-time="informationDetails.release_time"
							>
							</div>
						</div>
						<div class="wz_detail_pc_content_main">
							<div class="wz_detail_pc_content_left">
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_zan"
									@click="collectionInformation()"
								>
									<img
										class="wz_detail_pc_content_left_zan"
										src="https://zy.metaera.media/assets/images/kxwz/kxwz_zan_2.png"
										alt=""
										v-if="this.informationDetails.is_collection == 0"
									/>
									<img
										class="wz_detail_pc_content_left_zan"
										src="https://zy.metaera.media/assets/images/kxwz/kxwz_zan1_2.png"
										alt=""
										v-if="this.informationDetails.is_collection == 1"
									/>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_line"
								>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_wechat"
									@click="
										copyText(informationDetails.id, informationDetails.title)
									"
									@mouseenter="showLayer"
									@mouseleave="hideLayer"
								>
									<img
										src="https://zy.metaera.media/assets/images/kxwz/kxwz_wechat.png"
										alt=""
									/>
								</div>

								<div v-if="isHover" class="wz_detail_pc_content_wechat_code">
									<div class="wz_detail_pc_content_wechat_code_img">
										<canvas ref="canvas"></canvas>
									</div>
									<div class="wz_detail_pc_content_wechat_code_title"
										>微信扫码</div
									>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_twitter"
									@click="twitterShare(informationDetails.id)"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/36.png"
										alt=""
									/>
								</div>
								<div
									class="flex align_items_c justify_content_c fx pointer wz_detail_pc_content_copy"
									@click="
										copyText(informationDetails.id, informationDetails.title)
									"
								>
									<img
										src="https://zy.metaera.media/assets/images/home/686.png"
										alt=""
									/>
								</div>
							</div>
							<div class="wz_detail_pc_content_right">
								<div class="wz_detail_pc_content_txt ql-snow">
									<p
										class="ql-editor"
										:prohibitTran="informationDetails.prohibit_tran"
										v-html="
											(localLanguage == 2
												? informationDetails.content
													? informationDetails.content
													: informationDetails.content
												: informationDetails.content
											).replace(/\r?\n|\r/g, '')
										"
									>
									</p>
								</div>
								<div
									class="wz_detail_pc_content_author pointer"
									v-if="informationDetails.type != 2"
									@click="
										$router.push({
											path: '/home_wz',
											query: { id: userInfo.id },
										})
									"
								>
									<div class="wz_detail_pc_content_author_img">
										<img :src="userInfo.avatar_img" alt="" />
									</div>
									<div class="wz_detail_pc_content_author_name">
										<span class="wz_detail_pc_content_author_name1">{{
											userInfo.nickname
										}}</span>
										<span class="wz_detail_pc_content_author_name2">{{
											userInfo.desc
										}}</span>
									</div>
								</div>
								<div class="wz_detail_pc_content_tag flex">
									<div
										class="MainLeft_6_item pointer align_items_c"
										v-for="(item, index) in informationDetails.label_info"
										:key="index"
										@click="toLabel(item)"
									>
										{{ item.name }}
									</div>
								</div>
								<div
									class="wz_detail_pc_content_tip"
									v-if="informationDetails.id != null"
								>
									声明：本文由入驻MetaEra
									的作者撰写，观点仅代表作者本人，绝不代表MetaEra
									赞同其观点或证实其描述。
									提示：投资有风险，入市须谨慎。本资讯不作为投资理财建议。
								</div>
							</div>
						</div>
					</div>
					<div class="wz_detail_pc_content_bottom">
						<div class="wz_detail_pc_content_bottom_left">
							<div class="wz_detail_pc_content_bottom_left_title">
								主编推荐
							</div>
							<div class="wz_detail_pc_content_bottom_left_content">
								<div
									class=""
									v-for="(items, indexs) in recommendLists"
									:key="indexs"
								>
									<wzitem :item="items"></wzitem>
								</div>
							</div>
						</div>
						<div class="wz_detail_pc_content_bottom_right">
							<div
								class="wz_detail_pc_content_bottom_left_title flex justify_content_sb"
							>
								<div class="wz_detail_pc_content_bottom_left_title"
									>24小时热搜</div
								>
								<div
									class="flex align_items_c pointer"
									@click="
										$router.push({ path: '/search', query: { keywords: '' } })
									"
								>
									<div class="rsTitleLeft_point"></div>
									<div class="rsTitleLeft_point"></div>
									<div class="rsTitleLeft_point"></div>
								</div>
							</div>
							<div class="wz_detail_pc_content_bottom_right_content">
								<div class="" v-for="(items, indexs) in hotLists" :key="indexs">
									<wzitemsousuo :item="items"></wzitemsousuo>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="wz_detail_app" v-if="this.isMobileDevice">
					<div
						class="MainLeft_1"
						:prohibitTran="informationDetails.prohibit_tran"
					>
						{{
							localLanguage == 2
								? informationDetails.title
									? informationDetails.title
									: informationDetails.title
								: informationDetails.title
						}}
					</div>
					<div class="MainLeft_2_author flex align_items_c">
						<p style="margin-right: 10px">{{
							informationDetails.author.nickname
						}}</p>
						<p v-time="informationDetails.release_time"></p>
					</div>
					<div class="MainLeft_4 ql-snow">
						<p
							class="ql-editor"
							:prohibitTran="informationDetails.prohibit_tran"
							v-html="
								localLanguage == 2
									? informationDetails.content
										? informationDetails.content
										: informationDetails.content
									: informationDetails.content
							"
						></p>
					</div>
					<div class="MainLeft_6 flex">
						<div
							class="MainLeft_6_item pointer"
							v-for="(item, index) in informationDetails.label_info"
							:key="index"
							@click="toLabel(item)"
						>
							{{ item.name }}
						</div>
					</div>
					<div class="MainLeft_9" v-if="informationDetails.id != null">
						<span
							>声明：本文由入驻MetaEra
							的作者撰写，观点仅代表作者本人，绝不代表MetaEra
							赞同其观点或证实其描述。</span
						>
						<span
							>提示：投资有风险，入市须谨慎。本资讯不作为投资理财建议。</span
						>
					</div>
					<div class="MainLeft_tuijian" v-if="informationDetails.id != null">
						<div class="MainLeft_tuijian_title">相关推荐</div>
						<m_othresou></m_othresou>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	toUserInfo,
	getUserInformation,
	getInformationDetails,
	collectionInformation,
	cancelCollectionInformation,
} from "@/api/home";
import { uploadImage, submitReport } from "@/api/user";
import { homeHotList, getRecommendLists } from "@/api/home";
import { getShareSignaTure } from "@/api/user";
import { getToken } from "@/utils/auth";
import { myMixin } from "@/utils/mixin.js";
import clipboard from "clipboard";
import wx from "weixin-js-sdk";
import editorArticle from "./editorArticle";
import ChineseConv from "chinese-s2t";
import store from "@/store";
import wzitemsousuo from "./../../components/pc/wzitemsousuo.vue";
import wzitem from "./../../components/pc/wzitem.vue";
import QRCode from "qrcode";
import { eventBus } from "../../components/pc/leftTab";
export default {
	mixins: [myMixin],
	name: "",
	data() {
		return {
			localLanguage: 1,
			dialogVisible: false,
			informationDetails: {},
			userInfo: {},
			isHover: false,
			UserInformation: [],
			hotLists: [],
			recommendLists: [],
			author_id: "",
			id: "",
			report: {
				information_id: "",
				type: "",
				pics: "",
				desc: "",
				report_type: "1",
			},
			iscollect: false,
			isfixed: false,
			shareUrl: location.href,
			tupian: "",
			isMobileDevice: false,
			content: "",
			sharetitle:
				this.$route.query.title == null ? "MetaEra" : this.$route.query.title,
			sharedescs:
				"MetaEra是一家全球領先的 Web 3.0 華語資訊平台，覆蓋港澳台、東南亞、北美和中東等地區。憑借國際化的團隊和豐富的市場資源，MetaEra 致力於推動 Web 3.0 領域的信息傳播和行業發展。",
			wxsharelink: location.href,
			shareimg: "https://zy.metaera.media/logo_small.jpg",
		};
	},
	components: {
		editorArticle,
		ChineseConv,
		wzitem,
		wzitemsousuo,
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
		this.id = this.$route.query.id;
		this.getInformationDetails();
		// console.log("created" + this.sharetitle);
		// 添加路由守卫，每次路由切换都会重新解析查询字符串
		this.$router.beforeEach((to, from, next) => {
			if (to.path == "/gw_detail" && this.id != to.query.id) {
				// console.log("gw_detail", to.query.id);
				this.id = to.query.id;
				this.informationDetails = {};
				this.getInformationDetails();
			}
			next();
		});
		// 在 Vue.js 中监听路由变化
		this.$router.afterEach((to, from) => {
			// 更新页面的 <meta> 标签
			this.sharetitle = to.query.title;
			document
				.querySelector('meta[property="og:title"]')
				.setAttribute(
					"content",
					to.query.title == null ? "MetaEra" : to.query.title
				);
			document
				.querySelector('meta[property="og:description"]')
				.setAttribute(
					"content",
					to.query.title == null ? "MetaEra" : to.query.title
				);

			// 触发 Twitter Cards 更新
			if (typeof twttr !== "undefined") {
				twttr.widgets.load();
			}
		});
	},
	mounted() {
		var that = this;

		// console.log("mounted" + this.sharetitle);
		//刚进入页面时，获取窗口默认宽高度
		this.windowWidth = window.screen.width;
		this.windowHeight = window.screen.height;
		this.isMobileDevice = this.windowWidth < 800;
		//根据屏幕缩放自动获取页面宽高
		window.onresize = () => {
			return (() => {
				//窗口缩放自动获取页面宽高
				window.fullWidth = window.screen.width;
				window.fullHeight = window.screen.height;
				that.windowWidth = window.fullWidth; //宽
				that.windowHeight = window.fullHeight; //高
				that.isMobileDevice = this.windowWidth < 800;
			})();
		};
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	watch: {
		windowHeight(val) {
			let that = this;
		},
		windowWidth(val) {
			let that = this;
			that.isMobileDevice = that.windowWidth < 800;
		},
	},
	metaInfo() {
		return {
			title: ChineseConv.s2t(this.informationDetails.title),
			meta: [
				{
					name: "keywords",
					content:
						"Web3.0,DeFi,MetaEra,blockchain,DePIN,MetaEra,区块链媒体,BTC,比特币,blockchain Media,以太坊,Web3快讯,香港ETF,币圈,Web3资讯,区块链服务,Bitcoin News,区块链是什么,Ethereum News,区块链应用,Crypto News,区块链学习,区块链钱包,区块链活动,GameFi,区块链专栏,meme,区块链游戏,区块链投资,web3钱包,区块链赚钱,公链,链游,区块链投研,USDT,区块链知识,区块链入门",
				},
				{
					name: "description",
					content: ChineseConv.s2t(this.informationDetails.illustrate),
				},
				{
					property: "og:description",
					content: ChineseConv.s2t(this.informationDetails.illustrate),
				},
				{
					property: "og:type",
					content: "article",
				},
				{
					property: "og:site_name",
					content: "MetaEra",
				},
				{
					property: "og:title",
					content: ChineseConv.s2t(this.informationDetails.title),
				},
				{
					property: "og:image",
					content:
						this.informationDetails.pic != null &&
						this.informationDetails.pic.lenght > 0
							? this.informationDetails.pic
							: "https://zy.metaera.media/assets/images/pclogo.jpg",
				},
				{
					property: "twitter:description",
					content: ChineseConv.s2t(this.informationDetails.illustrate),
				},
				{
					property: "twitter:image",
					content:
						this.informationDetails.pic != null &&
						this.informationDetails.pic.lenght > 0
							? this.informationDetails.pic
							: "https://zy.metaera.media/assets/images/pclogo.jpg",
				},
				{
					property: "twitter:title",
					content: ChineseConv.s2t(this.informationDetails.title),
				},
				{
					property: "twitter:card",
					content: "summary",
				},
			],
		};
	},
	methods: {
		showLayer() {
			this.isHover = true;
			this.generateQRCode();
		},
		hideLayer() {
			this.isHover = false;
		},
		generateQRCode() {
			this.$nextTick(() => {
				const canvas = this.$refs.canvas;
				// const image = this.$refs.image;
				QRCode.toCanvas(
					canvas,
					"https://www.metaera.hk/gw_detail?id=" +
						this.id +
						"&title=" +
						this.informationDetails.title,
					(error) => {
						if (error) console.error(error);
						// 将Canvas内容转换为base64编码的图像数据，并设置为img的src属性
						// image.src = canvas.toDataURL();
					}
				);
			});
		},
		twitterShare(id) {
			window.open(
				"http://twitter.com/share?url=" +
					encodeURIComponent("https://web.metaera.hk/article/" + this.id) +
					"&text=" +
					encodeURIComponent(this.sharetitle) +
					"&display=popup&ref=plugin&src=share_button",
				"twitter",
				"height=500, width=750, top=" +
					100 +
					", left=" +
					100 +
					", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
			);
		},
		copyText(id, title) {
			let msg = "https://web.metaera.hk/article/" + id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		// 跳转标签详情
		toLabel(items) {
			this.$router.push({
				path: "/gw_dex",
				query: { id: items.id, name: items.name },
			});
		},
		homeHotList() {
			homeHotList({})
				.then((res) => {
					if (res.code == 200) {
						this.hotLists = res.data.hotLists;
					} else {
					}
				})
				.catch((error) => {});
		},
		getRecommendLists() {
			getRecommendLists({ page: 1, size: 10 })
				.then((res) => {
					if (res.code == 200) {
						this.recommendLists = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		//微信分享方法
		WxSharMenu() {
			let urlStr = encodeURIComponent(document.location.href.split("#")[0]);
			getShareSignaTure({
				url: urlStr,
			})
				.then((res) => {
					if (res.code == 200) {
						this.appId = res.data.appId;
						this.timestamp = res.data.timesTamp;
						this.nonceStr = res.data.nonceStr;
						this.signature = res.data.signaTure;
						wx.config({
							//注意appId,timestamp,nonceStr,signature四个参数，就是把当前url作为参数请求接口，是接口返回生成的
							debug: false, //生产环境需要关闭debug模式
							appId: this.appId, //appId通过微信服务号后台查看
							timestamp: this.timestamp, //生成签名的时间戳
							nonceStr: this.nonceStr, //生成签名的随机字符串
							signature: this.signature, //签名
							jsApiList: [
								"updateAppMessageShareData",
								"updateTimelineShareData",
							], //需要调用的JS接口列表
						});
						//分享参数，标题，描述，链接，图片title,desc,link,imgUrl
						wx.ready(() => {
							wx.updateAppMessageShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert("分享朋友圈设置成功")
								},
							});
							wx.updateTimelineShareData({
								title: this.sharetitle,
								desc: this.sharedescs,
								link: this.wxsharelink,
								imgUrl: this.shareimg,
								success: function () {
									// 设置成功
									//alert(desc)
								},
							});
						});
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		shareToQQRom() {
			//跳转地址
			window.open(
				"https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
					encodeURIComponent(this.shareUrl) +
					"&title=" +
					this.informationDetails.title +
					"&summary=" +
					this.informationDetails.title
			);
		},
		shareToMicroblog() {
			//跳转地址
			window.open(
				"https://service.weibo.com/share/share.php?url=" +
					encodeURIComponent(this.shareUrl) +
					"&title=" +
					this.informationDetails.title
			);
		},

		jubao() {
			const hasToken = getToken();
			if (hasToken) {
				this.dialogVisible = true;
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		collectionInformation() {
			const hasToken = getToken();
			if (hasToken) {
				if (this.informationDetails.is_collection == 0) {
					collectionInformation({ information_id: this.$route.query.id })
						.then((res) => {
							if (res.code == 200) {
								this.iscollect = true;
								this.getInformationDetails();
							}
						})
						.catch((error) => {
							// this.$message.error(error.message);
						});
				} else {
					cancelCollectionInformation({ information_id: this.$route.query.id })
						.then((res) => {
							if (res.code == 200) {
								this.iscollect = false;
								this.getInformationDetails();
							}
						})
						.catch((error) => {
							// this.$message.error(error.message);
						});
				}
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		async getInformationDetails() {
			// this.$loading.show();
			await getInformationDetails({ id: this.id })
				.then((res) => {
					if (res.code == 200) {
						this.informationDetails = res.data;
						this.sharetitle = ChineseConv.s2t(this.informationDetails.title);
						this.sharedescs = ChineseConv.s2t(
							this.informationDetails.illustrate
						);
						this.author_id = res.data.author_id;
						this.gettoUserInfo();
						this.WxSharMenu();
						this.homeHotList();
						this.getRecommendLists();
						this.$pvuv.getEvent(
							"page",
							this.informationDetails.id,
							this.informationDetails.title,
							this.informationDetails.author_id
						);
					} else {
						this.$message.error("当前文章不存在，请您浏览其他文章内容");
						this.$router.push({ path: "/" });
					}
				})
				.catch((error) => {
					this.$message.error("当前文章不存在，请您浏览其他文章内容");
					this.$router.push({ path: "/" });
				});
			this.$loading.hide();
		},
		gettoUserInfo() {
			toUserInfo({ id: this.author_id })
				.then((res) => {
					if (res.code == 200) {
						this.userInfo = res.data;
						// this.getUserInformation(res.data.id);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getUserInformation(id) {
			getUserInformation({ id: id })
				.then((res) => {
					if (res.code == 200) {
						this.UserInformation = res.data.list.splice(0, 5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		// 上传图片
		uploadImagecompany1(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.report.pics = res.data.url;
					} else {
						// that.$message.error(res.message);
					}
				})
				.catch(function (error) {
					// this.$message.error(res.message);
				});
		},
		submitReport() {
			this.report.information_id = this.$route.query.id;
			if (this.report.type == "") {
				this.$message.error("请选择举报类型");
			} else if (this.report.pics == "") {
				this.$message.error("请上传图片");
			} else if (this.report.desc == "") {
				this.$message.error("请输入问题描述");
			} else {
				submitReport(this.report)
					.then((res) => {
						if (res.code == 200) {
							this.$message.success("提交成功！");
							this.dialogVisible = false;
						} else {
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			}
		},

		toPage(item) {
			this.$router.push({
				path: "/gw_detail",
				query: { id: item.id },
			});
			location.reload();
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 20px;
	justify-content: center;
}

.MainLeft_1 {
	font-size: 32px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 30px;
	padding-left: 0px;
	padding-right: 0px;
}

.MainLeft_2_author {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #666666;
	margin-top: 36px;
	padding-left: 0px;
}

.MainLeft_3 {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	margin-top: 47px;
}

.MainLeft_4 {
	margin-top: 27px;
}

.MainLeft_4 p {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	line-height: 36px;
	margin-top: 25px;
}

.MainLeft_5 {
	margin-top: 20px;
}

.MainLeft_5_item {
	width: 72px;
	height: 72px;
	background: #ffffff;
	opacity: 1;
	border: 1px solid #f5f5f5;
	border-radius: 50px;
}

.MainLeft_5_item img {
	/* width: 25px;
	height: 25px; */
	width: 40px;
	height: 40px;
	background: #c7c7cb;
	opacity: 1;
}

.MainLeft_5_item span {
	font-size: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #333333;
	margin-top: 6px;
}

.MainLeft_5_item2 {
	font-size: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #333333;
	margin-top: 11px;
}

.MainLeft_6 {
	white-space: nowrap;
	overflow-x: auto;
	scrollbar-width: thin; /* Firefox 特定样式，用于设置滚动条宽度 */
	-ms-overflow-style: none;
	margin-top: 67px;
	padding-left: 0px;
	padding-right: 20px;
}
.MainLeft_6::-webkit-scrollbar {
	display: none; /* 隐藏滚动条在 WebKit 浏览器中（Chrome，Safari等） */
}

.MainLeft_6_item {
	margin-left: 20px;
	padding: 0 21px;
	height: 35px;
	background: #f9f9f9;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border-radius: 5px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	line-height: 28px;
	align-items: center;
	display: flex;
}

.MainLeft_6_item:nth-child(1) {
	margin-left: 0;
}

.MainLeft_7 {
	margin-top: 33px;
}

.MainLeft_7_t {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainLeft_7_search {
	width: 390px;
	height: 28px;
	background: #e8ecff;
	border-radius: 14px 14px 14px 14px;
	opacity: 1;
}

.MainLeft_7_search input {
	width: 50%;
	height: 90%;
	outline: none;
	border: none;
	background-color: #e8ecff;
	padding-left: 10px;
	border-radius: 20px 0 0 20px;
}

.MainLeft_7_search img {
	width: 12px;
	height: 12px;
	margin-right: 3px;
}

.change {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
}

.searchBtnBox {
	width: 64px;
	height: 28px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 0px 14px 14px 0px;
	opacity: 1;
	margin-left: 10px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

.kxItem_5 {
	width: 100%;
	height: 54px;
	background: #ffffff;
	border-radius: 12px 12px 12px 12px;
	opacity: 1;
	border: 1px solid #f5f5f5;
	margin-top: 20px;
}

.kxItem_5 .el-input {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
}

.kxItem_8 {
	margin-top: 18px;
}

.kxItem_8 img {
	width: 150px;
	height: 150px;
}

.kxItem_8 div {
	margin-top: 5px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a6a6ad;
}

.MainLeft_8 {
	margin-top: 41px;
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.MainLeft_8 span:nth-child(2) {
	margin-left: 12px;
}

.MainLeft_9 {
	margin-top: 21px;
	padding-left: 0px;
	padding-right: 0px;
}

.MainLeft_9 span {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.MainLeft_10 {
	display: block;
	width: 760px;
	height: 47px;
	background: #ffffff;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	border: 1px solid #dcdcdc;
	margin-top: 20px;
}

.fixed10 {
	position: fixed;
	bottom: 0;
}

.scBox {
	margin-left: 10px;
}

.scBox img {
	width: 25px;
	height: 25px;
}

.scBox span {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
	margin-left: 5px;
}

.shareBtn span {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	margin-right: 16px;
}

.shareBtnImg {
	margin-right: 8px;
	width: 24px;
	height: 24px;
}
.MainLeft_11 {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 38px;
}

.MainRight {
	display: block;
	width: 360px;
}

.MainRight_6 {
	width: 372px;
}

.MainRight_6_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #fff;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 8px 8px 0 0;
	padding: 16px 22px;
}

.dexBoxm {
	margin-top: 20px;
}

.wzitemLTime {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wzitem {
	height: 105px;
}

.wzitemL {
	height: 100%;
}

.wzitem img {
	width: 170px;
	height: 105px;
}

.wzitem2Img {
	width: 240px;
	height: 105px;
}

.wzitem2Img img {
	width: 100%;
	height: 100%;
}

@import url("../../assets/jubao.css");
.social-share .icon-wechat {
	position: relative;
}

.social-share .icon-wechat,
.social-share .icon-wechat:hover {
	background: url(https://staticn.jinse.cn/w/img/a72c3db.svg) 6px 6px no-repeat;
	background-size: 24px 24px;
}

.social-share .icon-wechat .wechat-qrcode {
	display: none;
	border: 1px solid #eee;
	position: absolute;
	z-index: 9;
	top: -205px;
	left: -84px;
	width: 200px;
	height: 192px;
	color: #666;
	font-size: 12px;
	text-align: center;
	background-color: #fff;
	box-shadow: 0 2px 10px #aaa;
	transition: all 0.2s;
	-webkit-tansition: all 0.35s;
	-moz-transition: all 0.35s;
}

.social-share .icon-wechat .wechat-qrcode.bottom {
	top: 40px;
	left: -84px;
}

.social-share .icon-wechat .wechat-qrcode.bottom:after {
	display: none;
}

.social-share .icon-wechat .wechat-qrcode h4 {
	font-weight: 400;
	height: 26px;
	line-height: 24px;
	font-size: 12px;
	background-color: #f3f3f3;
	margin: 0;
	padding: 0;
	color: #777;
}

.social-share .icon-wechat .wechat-qrcode .qrcode {
	width: 105px;
	margin: 10px auto;
}

.social-share .icon-wechat .wechat-qrcode .qrcode table {
	margin: 0 !important;
}

.social-share .icon-wechat .wechat-qrcode .help p {
	font-weight: 400;
	line-height: 16px;
	padding: 0;
	margin: 0;
}

.social-share .icon-wechat .wechat-qrcode:after {
	content: "";
	position: absolute;
	left: 50%;
	margin-left: -6px;
	bottom: -13px;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-top: 8px solid #fff;
}

.social-share .icon-wechat:hover .wechat-qrcode {
	display: block;
}
.js-dialog .js-dialog-content_radios .js-radio-item:nth-child(4n) {
	margin-right: 55px;
}
</style>
<style scoped>
@media screen and (max-width: 950px) {
	.wz_detail_pc {
		display: none;
	}
	.wz_detail_app {
		display: block;
	}
	.MainLeft_6 {
		margin-top: 20px;
		padding-left: 20px;
	}
	.MainLeft_9 {
		margin-top: 21px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.MainRight {
		display: none;
	}
	.MainLeft {
		width: 100%;
		margin: 0 auto;
	}
	.MainLeft_1 {
		margin-top: 0px;
		font-size: 25px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.MainLeft_2_author {
		margin-top: 10px;
		font-size: 14px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 16px;
		padding-left: 20px;
	}
	.MainLeft_3 {
		font-size: 14px !important;
		color: #1f1f21 !important;
	}

	.MainLeft_9 span {
		font-size: 14px !important;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #999999;
	}
	.MainLeft_10 {
		display: none;
		width: 100%;
		position: relative;
		margin-top: 20px;
	}
	.MainLeft_tuijian {
		width: 80%;
		margin-left: 5%;
		margin-top: 30px;
		padding: 16px;
		background: #ffffff;
		box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
		border-radius: 20px 20px 20px 20px;
		opacity: 1;
		margin-bottom: 30px;
	}
	.MainLeft_tuijian_title {
		font-size: 16px;
		font-weight: 500;
	}
	.MainLeft_6_item {
		width: 100px;
		font-size: 14px;
		height: 24px;
		align-items: center;
		display: flex;
	}
}
</style>
<style></style>
